import React from "react";

function AdobeAnalyticsBenefits() {
  return (
    <section className="section section--tools section--adobe-benefits section--bg-light-blue section--ldots-left-bottom-bg --section-medium-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Adobe Analytics benefits</h2>
          </div>
        </div>
        <div className="container__row --flex-center">
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/marketing-analytics.png"
                alt="Marketing Analytics"
              />
              <a className="">
                <h5 className="card__title">Marketing Analytics</h5>
              </a>
              <p className="card__description">
                Integrate data from virtually any channel, including web,
                mobile, video, IoT, app, social, and more. Then, you can analyze
                that data in real time to understand your customers on a whole
                new level.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/attribution.png"
                alt="Attribution"
              />
              <a>
                <h5 className="card__title">Attribution</h5>
              </a>
              <p className="card__description">
                With both rules-based and algorithmic attribution models, use
                machine learning and advanced statistics so you can make the
                right investment on the journeys that really matters.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/predictive-analytics.png"
                alt="Predictive Analytics"
              />
              <a>
                <h5 className="card__title">Predictive Analytics</h5>
              </a>
              <p className="card__description">
                Predictive tools are powered by AI and machine learning, plus
                integrate with Adobe Sensei, so that organizations can utilize
                big data for better gains.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdobeAnalyticsBenefits;
