import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function AdobeAnalyticsBanner() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading container__heading--platform">
              Adobe Analytics{" "}
            </h1>
            <img
              className="container__logo"
              src="../../solutions/platforms/solution-partner.png"
              alt="Adobe Experience Manager"
            />
            <h2 className="container__sub-heading container__sub-heading--platform">
              See beyond vanity metrics to find insights that drive the bottom
              line.
            </h2>
            <h6 className="container__content">
              Hashout is an Adobe Solutions Partner with focussed data practice
              and functional expertise to help design, instrument, and report on
              the metrics that are the most relevant to your business.
            </h6>
            {/* <a className="container__link" href="">
            Read Google Analytics Vs Adobe Analytics comparison
          </a> */}
            <button
              className="button container__button container__button--platform button--purple"
              onClick={openModal}
            >
              Get 40 hrs free consultation!
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/adobe-analytics.png"
              alt="Adobe Analytics"
            />
          </div>
        </div>
      </div>

      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="Adobe-Analytics-Banner"
        page="Adobe-Analytics"
        
       
      />
    </section>
  );
}

export default AdobeAnalyticsBanner;
