import React from "react";

function AdobeAnalyticsServices() {
  return (
    <section className="section section--services section--wave-bg --section-normal-tb-padding">
      <div className="container">
        <div className="container__row --flex-center --section-small-tb-padding">
          <div className="container__col-md-5 container__col-xs-12 --section-large-tb-padding">
            <h2 className="container__heading">Our Adobe Analytics Services</h2>
            <div className="container__row">
              <div className="container__col-md-10">
                <div className="container__services">
                  <div className="container__row">
                    <div className="container__col-md-2 container__col-xs-2">
                      <img src="../../solutions/checkmark.png" alt="Hashout" />
                    </div>
                    <div className="container__col-md-10 container__col-xs-10">
                      <h5 className="container__sub-heading">
                        New Setup (Strategy, Design and Implementaion)
                      </h5>
                    </div>
                  </div>
                  <div className="container__row">
                    <div className="container__col-md-2  container__col-xs-2">
                      <img src="../../solutions/checkmark.png" alt="Hashout" />
                    </div>
                    <div className="container__col-md-10  container__col-xs-10">
                      <h5 className="container__sub-heading">
                        Assessment and Optimization{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="container__row">
                    <div className="container__col-md-2  container__col-xs-2">
                      <img src="../../solutions/checkmark.png" alt="Hashout" />
                    </div>
                    <div className="container__col-md-10  container__col-xs-10">
                      <h5 className="container__sub-heading">
                        Ongoing Report Management
                      </h5>
                    </div>
                  </div>
                  <div className="container__row">
                    <div className="container__col-md-2  container__col-xs-2">
                      <img src="../../solutions/checkmark.png" alt="Hashout" />
                    </div>
                    <div className="container__col-md-10  container__col-xs-10">
                      <h5 className="container__sub-heading">Integrations</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container__content">
              Contact us to learn about how Adobe Analytics and Adobe Target can
              close the Experience gap
            </div>
          </div>
          <div className="container__col-md-6 container__col-xs-12 container__col-md-offset-1 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/adobe-analytics-services.png"
              alt="adobe analytics services"
            />
          </div>
        </div>
      </div>
      {/* <!-- <img className="section__wave" src="../../solutions/wave.svg" alt="Hashout" /> --> */}
    </section>
  );
}

export default AdobeAnalyticsServices;
