import React from "react";
import { Helmet } from "react-helmet";

function SolutionsHelmet() {
	return (
		<>
			<Helmet
				style={[
					{
						cssText: `
            html {
                font-size: 10px;
            }
        `,
					},
				]}
			/>
		</>
	);
}

export default SolutionsHelmet;
